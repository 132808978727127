import './_cookieconsent.min';
import './cookie';
import autosize from 'autosize';
import Swiper, { Navigation, Pagination } from 'swiper';
import './form'

Swiper.use([Navigation, Pagination]);

const swiper = new Swiper('.swiper', {
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

autosize(document.querySelectorAll('textarea'));
