const form = document.getElementById('contactform');

document.addEventListener('submit', function (event) {

  event.preventDefault();

  fetch(form.action, {
    method: 'POST',
    body: new FormData(event.target),
  }).then(function (response) {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  }).then(function (data) {
    if(data.message) {
      var response = document.getElementById('contactform--response');
      response.innerHTML = data.message;
     if(data.code === 200) {
       form.remove();
     }
    }
  }).catch(function (error) {
    console.warn(error);
  });
});
